<template>
    <div class="cookie-consent" data-pagearea="cookies_bar">
        <div class="text-sm">
            {{ $t('Chceme neustále zlepšovat naše produkty a služby. K tomu potřebujeme váš souhlas k práci s cookies. Umožníte nám to?') }} <AtomsGlobalLink :to="cache.getPageUrl(21913)" class="font-bold text-primary-500 underline">{{ $t('Více zde') }}</AtomsGlobalLink>
        </div>
        <div class="cookie-consent__settings" :class="{ 'cookie-consent__settings--is-open': showSettings }">
            <label class="cookie-consent__option cookie-consent__option--checked">
                <AtomsFormsCheckbox class="opacity-40" :display-as-switch="true" v-model="cookie0" :disabled="true" />{{ $t('Funkční') }}
            </label>
            <label class="cookie-consent__option" :class="{ 'cookie-consent__option--checked': cookie1 }">
                <AtomsFormsCheckbox :display-as-switch="true" v-model="cookie1" @input="cookie1 = $event.target.checked" />{{ $t('Preferenční') }}
            </label>
            <label class="cookie-consent__option" :class="{ 'cookie-consent__option--checked': cookie2 }">
                <AtomsFormsCheckbox :display-as-switch="true" v-model="cookie2" @input="cookie2 = $event.target.checked" />{{ $t('Analytické') }}
            </label>
            <label class="cookie-consent__option" :class="{ 'cookie-consent__option--checked': cookie3 }">
                <AtomsFormsCheckbox :display-as-switch="true" v-model="cookie3" @input="cookie3 = $event.target.checked" />{{ $t('Personalizované') }}
            </label>
        </div>

        <MoleculesButtonsButton class="w-full text-center mt-6" type="conversion" @click="saveConsent(1 | 2 | 4)" v-if="!showSettings">{{ $t('Jasně') }}</MoleculesButtonsButton>
        <div class="flex flex-wrap gap-2 pt-2">
            <MoleculesButtonsButton class="flex-[1.2] tablet:flex-1" :ghost="true" :small="true" type="conversion" @click="saveSettings()" v-if="showSettings">{{ $t('Souhlasím s vybranými') }}</MoleculesButtonsButton>
            <MoleculesButtonsButton class="flex-[1.2] tablet:flex-1" :ghost="true" :small="true" type="conversion" @click="showSettings = true" v-if="!showSettings">{{ $t('Nastavení') }}</MoleculesButtonsButton>
            <MoleculesButtonsButton class="flex-1" :ghost="true" :small="true" type="conversion" @click="saveConsent('0')">{{ $t('Ne, jsem tu na tajnačku') }}</MoleculesButtonsButton>
        </div>
    </div>
</template>
<script setup>

const emit = defineEmits(['consentGranted']);

const cache = useCache();

let consentValue = ref(parseInt(useShopCookie().read('cookieConsent') ?? 0));

const showSettings = ref(consentValue.value !== 0);

const cookie0 = ref(true);
const cookie1 = ref((consentValue.value & 1) === 1);
const cookie2 = ref((consentValue.value & 2) === 2);
const cookie3 = ref((consentValue.value & 4) === 4);

const saveConsent = (value) => {
    consentValue.value = value;
    useCookie(useShopZone().value.domain + '-cookieConsent').value = consentValue.value;
    emit('consentGranted');
}

const saveSettings = () => {
    const value = (cookie1.value && 1) | (cookie2.value && 2) | (cookie3.value && 4);
    saveConsent(value);

    if (value == 0) {
        useBaseAnalytics().pushEvent('user_interaction', {
            interaction_name: 'cookies_bar_decline_all'
        });
    }
    else if (value == 7) {
        useBaseAnalytics().pushEvent('user_interaction', {
            interaction_name: 'cookies_bar_accept_all'
        });
    }
    else {

        const settings = [];

        if ((value & 1) === 1) {
            settings.push('Preferenční');
        }

        if ((value & 2) === 2) {
            settings.push('Analytické');
        }

        if ((value & 4) === 4) {
            settings.push('Personalizované');
        }

        useBaseAnalytics().pushEvent('user_interaction', {
            interaction_name: 'cookies_bar_accept_specified',
            page_element_specification: settings.join(',')
        });
    }
}

</script>
<style lang="postcss">
.cookie-consent {
    @apply fixed left-0 right-0 bottom-0 z-30 p-4 border-t-2 border-primary-500 bg-neutral-50 tablet:p-6 desktop:p-8 desktop:left-auto desktop:right-4 desktop:bottom-4 desktop:max-w-[500px] desktop:border-2;

    &__settings {
        @apply space-y-4 max-h-0 overflow-hidden;

        &--is-open {
            animation: slide-toggle 1s;
            animation-fill-mode: forwards;
            @apply my-6;
        }
    }

    &__option {
        @apply flex;

        &--checked {
            @apply font-bold;
        }
    }
}
</style>